<script>
import PaymentCardsList from "@/components/PaymentCardComponents/PaymentCardsList.vue";

export default {
  components: {
    PaymentCardsList,
  },
};
</script>
  <template>
  <PaymentCardsList />
</template>